import * as DropdownUserTemplate from 'app/client/core/views/templates/dropdownUser.html?caveman';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';_ds.push(d); var _d0 = d.owners; for (var _i0 = 0, _len0 = (d.owners || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var user = _d0[_i0];str += '  ';str += DropdownUserTemplate.render( { disabled: user.disabled, user: user, type: 'Owner', multiple: user.multiple });str += '';} d = _ds.pop();str += '';if (!d.owners || d.owners.length === 0) {str += '  ';str += DropdownUserTemplate.render( { type: 'Owner' });str += '';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
