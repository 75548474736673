import WorkflowModel from 'app/client/core/js/models/workflow';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Workflow'], WorkflowModel]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var isPlural = d.isPlural !== undefined ? d.isPlural : false;str += '';var inline = d.inline !== undefined ? d.inline : false;str += '<div class="workflow-label ';str += _CfS( inline ? ' is-inline' : '' );str += '">'+'  <span class="label">';str += _CfS( App.Model.Workflow.getLabel({ isPlural: isPlural }) );str += '</span>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
