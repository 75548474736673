import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html?caveman';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';if (d.new.name) {str += '    ';if (d.old.name) {str += 'moved';} else {str += 'added';}str += ' ';str += (d.story);str += ' to the Epic'+'    ';str += EpicAutoLinkTemplate.render( d.new);str += '  ';} else {str += '    removed ';str += (d.story);str += ' from'+'    ';if (d.old.name) {str += '      the Epic ';str += EpicAutoLinkTemplate.render( d.old);str += '    ';} else {str += '      a <strong>Deleted Epic</strong>'+'    ';}str += '  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
