import Utils from 'app/client/core/js/modules/utils';
import ResetYourPasswordController from 'app/client/login/js/controllers/resetYourPassword';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Utils'], Utils],
[['Controller', 'ResetYourPassword'], ResetYourPasswordController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


export function render(_d) {
  let html = '<div id="reset-form" data-controller="ResetYourPassword" class="login-form-tab">  <form method="post" data-on-submit="sendResetCode">    <div class="forgot-blurb">Forgot your password? Enter your email address and we\'ll email you a link you can use to reset your password.</div>    <div class="form-input email" data-validate="isEmail">      <input id="reset-email" type="text" name="email" spellcheck="false" class="textfield" placeholder="name@example.com" data-tabindex />    </div>    <div class="server-messages"></div>    <div class="form-action">      <button id="reset-button" class="action blue" data-on-click="sendResetCode" data-tabindex>Email Reset Code <span class="fa fa-arrow-right"></span></button>    </div>    <div class="problems-logging-in">      <a href="/login" data-on-click="App.Utils.fadePageOut">Or, try logging in again.</a>    </div>  </form></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
