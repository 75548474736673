import * as EpicStateIconTemplate from 'app/client/core/views/templates/epicStateIcon.html?caveman';import EpicModel from 'app/client/core/js/models/epic';
import Format from 'app/client/core/js/modules/format';
import EpicsController from 'app/client/core/js/controllers/epics';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Epic'], EpicModel],
[['Format'], Format],
[['Controller', 'Epics'], EpicsController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';if (d) {str += '<a href="';str += _CfS(d.url || App.Model.Epic.generateURL(d));str += '" class="entity-link-with-tooltip entity-title-link" data-model="Epic" data-id="';str += _CfS(d.id);str += '" ';if (!d.noTooltip) {str += 'data-tooltip data-tooltip-fn="App.Controller.Epics.renderEpicTooltip"';}str += '>'+'  <span class="epic-autolink-icon">'+'    ';str += EpicStateIconTemplate.render( d);str += '  </span>'+'  ';str += (d.formatted_name || App.Format.sanitizeAndEmojify(d.name));str += '</a>';} else {str += '  <em>Deleted Epic</em>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
