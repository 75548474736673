import Utils from 'app/client/core/js/modules/utils';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Utils'], Utils]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.loading_icons && d.loading_icons.length) {str += '  ';var icon = App.Utils.sample(d.loading_icons);str += '  <div class="icon">'+'    <span class="fa';if (icon.spin) {str += ' fa-spin-slow';}str += '"><img src="';str += _CfS(icon.url);str += '" alt="" /></span>'+'  </div>'+'  <h3>';str += _CfS(icon.message || d.defaults.message);str += '</h3>';} else {str += '  <div class="icon">'+'    <span class="fa"><img class="default';if (d.defaults.spin) {str += ' fa-spin-slow';}str += '" src="';str += _CfS(d.defaults.url);str += '" alt="" /></span>'+'  </div>'+'  <h3>';str += _CfS(d.defaults.message);str += '</h3>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
