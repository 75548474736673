import StoryModel from 'app/client/core/js/models/story';
import StoryDialogController from 'app/client/core/js/controllers/storyDialog';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Story'], StoryModel],
[['Controller', 'StoryDialog'], StoryDialogController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<a'+'  href="';str += _CfS(d.url);str += '"'+'  data-controller="StoryDialog"'+'  data-on-click="openFromMessage"'+'  data-model="Story"'+'  data-id="';str += _CfS(d.id);str += '"'+'  class="action mini elevated"'+'>'+'  <span class="fa fa-eye"></span> View'+'</a>';if (!d.isCreateAnotherEnabled) {str += '<button'+'  data-controller="';str += _CfS(d.createAnotherController);str += '"'+'  data-on-click="';str += _CfS(d.createAnotherOnClick);str += '"'+'  class="action mini elevated"'+'>'+'  Create Another'+'</button>';}str += '<button class="action mini elevated clipboard" data-clipboard-text="';str += _CfS(d.url);str += '">Copy Link</button>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
