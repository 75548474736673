import Utils from 'app/client/core/js/modules/utils';
import SsoController from 'app/client/login/js/controllers/sso';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Utils'], Utils],
[['Controller', 'Sso'], SsoController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div id="sso-login-form" data-controller="Sso" class="login-form-tab">'+'  <form data-on-submit="login">'+''+'    <div id="standard-login-tab">'+'      <div class="form-input email" data-validate="isSubdomain">'+'        <label for="workspace_slug">Workspace Slug</label>'+'        <input id="workspace_slug" type="text" name="workspace_slug" spellcheck="false" class="textfield" placeholder="';str += _CfS(d.sampleOrgSlug);str += '" data-tabindex />'+'        <div class="form-helper">'+'          Your slug can be found in the app URL, e.g.<br />'+'          ';str += _CfS( BRAND.DOMAIN_APP_WEBSITE );str += '/<strong>';str += _CfS(d.sampleOrgSlug);str += '</strong>'+'        </div>'+'      </div>'+'    </div>'+''+'    <div class="server-messages"></div>'+''+'    <div class="form-action">'+'      <button type="submit" id="login-button" class="action blue" data-tabindex>Log in with SSO <span class="fa fa-arrow-right"></span></button>'+'    </div>'+''+'    <div class="problems-logging-in">'+'      <a href="/" data-on-click="App.Utils.fadePageOut">Log in without SSO</a>'+'    </div>'+''+'  </form>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
