import Format from 'app/client/core/js/modules/format';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Format'], Format]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<button type="button" class="action mini flat-white" data-on-click="closeDeleteDialog">Cancel</button>';if (d.count === 0) {str += '  <button type="button" class="action mini gray" disabled><span class="fa fa-archive"></span> <span>Archive 0 stories</span></button>';} else if (d.canDelete) {str += '  <button type="button" class="action mini red" data-on-click="deleteAll"><span class="fa fa-trash"></span> <span>Delete <span class="bulk-story-count">';str += _CfS(App.Format.pluralize(d.count, 'story', 'stories'));str += '</span></span></button>';} else {str += '  <button type="button" class="action mini white" data-on-click="archiveAll"><span class="fa fa-archive"></span> <span>Archive <span class="bulk-story-count">';str += _CfS(App.Format.pluralize(d.count, 'story', 'stories'));str += '</span></span></button>';}str += '<p class="note">';if (!d.canDelete) {str += 'All stories must first be archived before they can be deleted.';}str += '</p>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
