import * as StoryDiffActionTemplate from 'app/client/core/views/templates/activity/storyDiffAction.html?caveman';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';if (d.isMention) {str += '    mentioned you in'+'  ';} else if (d.isGroupMention) {str += '    mentioned your group in'+'  ';} else {str += '    updated'+'  ';}str += '  the description of'+'  ';str += (d.story);str += '  ';str += StoryDiffActionTemplate.render( { actionID: d.action.id, changeID: d.change.id, model: d.modelName, prop: d.prop });str += '</div>';if (d.isMention || d.isGroupMention) {str += '  <div class="comment markdown-formatted">'+'    ';str += (d.description);str += '  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
