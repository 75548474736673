import StoryModel from 'app/client/core/js/models/story';
import AddExternalLinkController from 'app/client/core/js/controllers/addExternalLink';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Story'], StoryModel],
[['Controller', 'AddExternalLink'], AddExternalLinkController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="external-link-header">'+'  Add a link...'+'</div>'+''+'<div class="external-link-body" data-controller="AddExternalLink" data-model="Story" data-id="';str += _CfS(d.id);str += '" data-validate="isUrl">'+'  <form data-on-submit="linkStoryToExternalLink">'+'    <input type="text" id="external-link-url" class="textfield" placeholder="';str += _CfS(d.placeholder);str += '" />'+'    <button type="submit" class="action micro green">Add Link</button>'+'  </form>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
