import * as AddNewLabelActionsTemplate from 'app/client/core/views/templates/addNewLabelActions.html?caveman';
import * as LabelColorPickerTemplate from 'app/client/core/views/templates/labelColorPicker.html?caveman';import AddNewLabelController from 'app/client/core/js/controllers/addNewLabel';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'AddNewLabel'], AddNewLabelController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="add-new-dialog add-new-label clearfix" data-controller="AddNewLabel">'+'  <form data-on-submit="save">'+'    <div class="section-head">Label Name</div>'+'    <input class="textfield" type="text" name="name" value="" data-1p-ignore="true" data-form-type="other" data-lpignore="true" data-tabindex />'+'    ';str += LabelColorPickerTemplate.render( d);str += '    <div class="form-actions add-new-action">'+'      ';str += AddNewLabelActionsTemplate.render( d);str += '    </div>'+'  </form>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
