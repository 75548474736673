import * as EpicStateIconTemplate from 'app/client/core/views/templates/epicStateIcon.html?caveman';import EpicModel from 'app/client/core/js/models/epic';
import EpicController from 'app/client/stories/js/controllers/epic';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Epic'], EpicModel],
[['Controller', 'Epic'], EpicController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div data-id="';str += _CfS(d.id);str += '" class="epic-parent relative">'+'  <div class="epic-nav-item list-nav-item ';if (d.archived) {str += 'is-archived';}str += ' ';if (d.active) {str += 'active';}str += '" id="epic-';str += _CfS(d.id);str += '" data-model="Epic" data-id="';str += _CfS(d.id);str += '" data-on-click="toggle" data-context-menu="contextMenuItems" ';if (d.name.length > 16) {str += 'data-tooltip data-tooltip-fn="App.Controller.Epic.epicSidebarTooltip"';}str += '>'+'    <span class="fa ';if (d.active) {str += 'fa-check-square';} else {str += 'fa-square-o';}str += '"></span>'+'    ';str += EpicStateIconTemplate.render( d);str += '    <span class="name">';str += _CfS(d.name);str += '</span>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
