
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="referral-card">'+'    <h4 class="referral-card-title">';str += _CfS(d.title);str += '</h4>'+'    <div class="referral-card-inner">'+'        <div class="referral-image-wrapper">'+'            <img src=';str += _CfS(d.image.src);str += ' alt=';str += _CfS(d.image.alt);str += ' />'+'        </div>'+''+'        ';str += (d.content);str += '    </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
