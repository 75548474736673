import * as ChangePasswordTemplate from 'app/client/settingsShared/views/templates/changePassword.html?caveman';
import * as ManageSessionsTemplate from 'app/client/settingsShared/views/templates/manageSessions.html?caveman';
import * as ManageTwoFactorAuthTemplate from 'app/client/settingsShared/views/templates/manageTwoFactorAuth.html?caveman';
import * as SettingsPageHeaderTemplate from 'app/client/settingsShared/views/templates/settingsPageHeader.html?caveman';import TabsController from 'app/client/core/js/controllers/tabs';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'Tabs'], TabsController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="organization-settings" class="settings-pane">'+'  <div class="clearfix">'+'    '+''+'    <div class="right-column">'+'      ';str += SettingsPageHeaderTemplate.render( { pageName: "Security" });str += '      <div id="manage-security" class="form">'+'        <div class="tabs file-tabs clearfix" data-controller="Tabs">'+'          <a href="#" id="manage-password-link" class="tab active" data-on-click="tab" data-tab="password-tab">Password</a>'+'          <a href="#" id="manage-two-factor-auth-link" class="tab" data-on-click="tab" data-tab="two-factor-auth-tab">Two-factor Auth</a>'+'          <a href="#" id="manage-sessions-link" class="tab" data-on-click="tab" data-tab="sessions-tab">Sessions</a>'+'        </div>'+''+'        <div id="password-tab">'+'          ';str += ChangePasswordTemplate.render( d);str += '        </div>'+''+'        <div id="two-factor-auth-tab" class="hidden">'+'          ';str += ManageTwoFactorAuthTemplate.render( d);str += '        </div>'+''+'        <div id="sessions-tab" class="hidden">'+'          ';str += ManageSessionsTemplate.render( d);str += '        </div>'+'      </div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
