import ProfileModel from 'app/client/core/js/models/profile';
import ManageBillingEmailController from 'app/client/settingsShared/js/controllers/manageBillingEmail';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Profile'], ProfileModel],
[['Controller', 'ManageBillingEmail'], ManageBillingEmailController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var billingEmail = d?.paymentPlan2?.billing_contact;str += '';var ownerEmail = App.Model.Profile.getOwnerEmail();str += '<div id="manage-email-plan" data-controller="ManageBillingEmail">'+''+'  <div class="blurb">'+'    All billing related emails for this organization go to'+'    ';if (billingEmail) {str += '      <strong data-id="billingEmail">';str += _CfS(billingEmail);str += '</strong>.'+'    ';} else {str += '      the primary owner email <strong>';str += _CfS(ownerEmail);str += '</strong>.'+'    ';}str += '  </div>'+''+'  <div class="server-messages"></div>'+''+'  <form method="post" action="/user/email" data-on-submit="addBillingEmail">'+'    <div class="form-input email" data-validate="isEmail">'+'      <label>Update Billing Email Address</label>'+'      <input type="text" value="" placeholder="name@example.com" name="email_address" spellcheck="false" class="textfield" data-tabindex />'+'    </div>'+''+'    <div>'+'      <button class="action green add-billing-email" data-on-click="addBillingEmail" data-tabindex>Update Billing Email</button>'+'    </div>'+'  </form>'+''+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
