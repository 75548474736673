import IterationModel from 'app/client/core/js/models/iteration';
import IterationController from 'app/client/stories/js/controllers/iteration';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Iteration'], IterationModel],
[['Controller', 'Iteration'], IterationController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div data-id="';str += _CfS(d.id);str += '" class="iteration-parent relative">'+'  <div class="iteration list-nav-item ';if (d.active) {str += 'active';}str += '" id="iteration-';str += _CfS(d.id);str += '"'+'        data-model="Iteration" data-id="';str += _CfS(d.id);str += '" data-on-click="toggle"'+'        data-context-menu="contextMenuItems" ';if (d.name.length > 16) {str += 'data-tooltip="';str += _CfS(d.name);str += '"';}str += '>'+'    <span class="fa';if (d.active) {str += ' fa-check-square';} else {str += ' fa-square-o';}str += '"></span>'+'    ';str += (App.Controller.Iteration.renderStatusIcon(d.id, d.status));str += '    <span class="name">';str += _CfS(d.name);str += '</span>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
