import * as CommitTemplate from 'app/client/core/views/templates/commit.html?caveman';
import * as StoryLinkedBranchesTemplate from 'app/client/core/views/templates/storyLinkedBranches.html?caveman';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';str += '<div class="story-branches-container">'+'  ';str += StoryLinkedBranchesTemplate.render( d);str += '</div>';if (d.commits && d.commits.length > 0) {str += '  <div class="section-head">Commits <span class="fa fa-question-circle" data-tooltip="Individual commits that mention stories are listed here. If you want to link a branch to this story, use the branch name suggested in the Git Helpers dialog in the top right."></span></div>'+''+'  <div class="commits">'+'    ';_ds.push(d); var _d1 = d.commits; for (var _i1 = 0, _len1 = (d.commits || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;d = _d1[_i1];str += '      ';str += CommitTemplate.render( d);str += '    ';} d = _ds.pop();str += '  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
