import IterationModel from 'app/client/core/js/models/iteration';
import View from 'app/client/core/js/modules/view';
import IterationPageController from 'app/client/iterations/js/controllers/iterationPage';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Iteration'], IterationModel],
[['View'], View],
[['Controller', 'IterationPage'], IterationPageController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div '+'  class="iteration-page"'+'  data-controller="IterationPage" '+'  data-model="Iteration" '+'  data-id="';str += _CfS(d.id);str += '" '+'  data-component-property-key="iterationPage" '+'  data-component-key="iterationPage" '+'  data-component-props="';str += _CfS( App.View.serializeProps(d) );str += '"'+'></div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
