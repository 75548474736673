import CommentModel from 'app/client/core/js/models/comment';
import CommentController from 'app/client/core/js/controllers/comment';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Comment'], CommentModel],
[['Controller', 'Comment'], CommentController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<a href="#" class="action micro flat-white" data-controller="Comment" data-on-click="toggleCommentOrder">'+'  ';if (App.Model.Comment.commentsAreAscending()) {str += '    Oldest first'+'  ';} else {str += '    Newest first'+'  ';}str += '</a>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
