import * as ModalDialogNavigationTemplate from 'app/client/core/views/templates/modalDialogNavigation.html?caveman';import Dialog from 'app/client/core/js/modules/dialog';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Dialog'], Dialog]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="modal-dialog-overlay" data-on-click="App.Dialog.close"';if (d.id) {str += ' id="';str += _CfS(d.id);str += '-overlay"';}str += '></div>'+'<div class="modal-dialog"';if (d.id) {str += ' id="';str += _CfS(d.id);str += '"';}str += '>'+'  <div class="container">'+'    <div id="';str += _CfS(d.id);str += '-portals" class="dialog-content-portals"></div>'+'    ';if (d.arrowTopLeft) {str += '      <div class="arrow-top-left"><span class="fa fa-caret-left"></span></div>'+'    ';}str += '    <div class="modal-dialog-nav">';str += ModalDialogNavigationTemplate.render( d);str += '</div>'+'    <div class="content ';str += _CfS(d.classNames);str += '">'+'      <a href="#" class="close-button fa fa-times" data-on-click="App.Dialog.close" data-tooltip="Close" data-tooltip-shortcut="(esc)" role="button" aria-label="Close dialog"></a>'+'      ';if (d.expandable) {str += '        <button class="expand-button fa ';str += _CfS( App.Dialog.getStoryDialogExpandedLabel().icon );str += '" data-tooltip="';str += _CfS( App.Dialog.getStoryDialogExpandedLabel().label );str += '" data-on-click="App.Dialog.expand"></button>'+'      ';}str += ' '+'      ';str += (App.Dialog.renderPinButton(d.pinnableButtonProps));str += '      ';str += (App.Dialog.renderBreadcrumbs(d.breadcrumbsProps));str += '      <div class="scrollable-content">';str += (d.content);str += '</div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
