import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import Utils from 'app/client/core/js/modules/utils';
import ManageBillingController from 'app/client/settingsShared/js/controllers/manageBilling';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'PaymentPlan2'], PaymentPlan2Model],
[['Utils'], Utils],
[['Controller', 'ManageBilling'], ManageBillingController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';const plan = d.paymentPlan2;str += '';const hasReferral = App.Model.PaymentPlan2.hasReferral(plan);str += '';const referralStatus = App.Model.PaymentPlan2.getReferralStatus(plan);str += '';const delinquentStatus = App.Model.PaymentPlan2.getDelinquentStatus(plan);str += '';const shouldShowUnpaidBalance = delinquentStatus && delinquentStatus.account_balance > 0;str += '<div class="billing-plan-summary">'+''+'<div class="new-plan-overview"></div>';str += (App.Controller.ManageBilling.renderPaidSeatsRemaining());str += '';if (hasReferral) {str += '  <div class="form-help-message">'+'    <span class="help-icon fa fa-info-circle"></span>'+'    <span>You are currently receiving two free months thanks to your referral offer! Your credit card will not be charged until <strong>';str += _CfS(referralStatus.referral_period_end);str += '</strong>.</span>'+'  </div>';}str += '';if (shouldShowUnpaidBalance) {str += '  <div class="account-balance unpaid-balance">You have an unpaid balance on your account of <strong>$';str += _CfS(App.Utils.toMoney(delinquentStatus.account_balance));str += '.</strong></div>';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
