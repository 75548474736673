import OrganizationModel from 'app/client/core/js/models/organization';
import InstallationModel from 'app/client/core/js/models/installation';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Organization'], OrganizationModel],
[['Model', 'Installation'], InstallationModel]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div data-model="Organization" data-id="';str += _CfS(d.organization.id);str += '">'+'  ';if (d.credential) {str += '    <p>You\'re already authenticated with <strong>';str += _CfS(d.application.identifier);str += '</strong>. <span class="fa fa-check-circle"></span></p>'+'  ';} else {str += '    <p>Are you sure you want to authorize <strong>';str += _CfS(d.organization.name);str += '</strong>?</p>'+''+'    <input type="hidden" name="client_id" value="';str += _CfS(d.installation.id);str += '" />'+'    <input type="hidden" name="organization_id" value="';str += _CfS(d.organization.id);str += '" />'+'    <input type="hidden" name="redirect_uri" value="';str += _CfS(d.redirectUri);str += '" />'+'    <input type="hidden" name="state" value="';str += _CfS(d.redirectState);str += '" />'+''+'    <div class="actions" data-model="Installation" data-id="';str += _CfS(d.installation.id);str += '">'+'      <button type="button" class="action red" data-on-click="deny">Deny</button>'+'      <button type="submit" class="action green" data-on-click="approve">Approve</button>'+'    </div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
