import ManagePaymentPlanController from 'app/client/settingsShared/js/controllers/managePaymentPlan';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'ManagePaymentPlan'], ManagePaymentPlanController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div id="manage-payment-plan" data-controller="ManagePaymentPlan">'+'  ';str += (App.Controller.ManagePaymentPlan.renderPlanTab());str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
