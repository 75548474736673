import UploaderController from 'app/client/core/js/controllers/uploader';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'Uploader'], UploaderController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="drop-overlay">'+'  <div class="drop-content">'+'    <h2><span class="fa fa-cloud-upload"></span> ';str += _CfS(d.title);str += '</h2>'+'    ';if (d.showModalTip) {str += '      <p class="note">Hold down <em>shift</em> to crop or add metadata</p>'+'    ';}str += '    <div class="while-uploading hidden">'+'      <span class="progressbar">'+'        <span class="bar" style="width: 0%;"></span>'+'      </span>'+'      <div class="actions">'+'        <a href="#" data-controller="Uploader" data-on-click="cancelUpload"><span class="fa fa-times"></span> Cancel</a>'+'      </div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
