import ImportModel from 'app/client/core/js/models/import';
import Utils from 'app/client/core/js/modules/utils';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Import'], ImportModel],
[['Utils'], Utils]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.change.new === 'waiting-for-mapping') {str += '  <div class="value">'+'    <p style="margin-top: 0;">'+'      <strong>';str += _CfS(App.Utils.capitalize(d.importObj.source));str += ' Import: Download complete.</strong>'+'      <br />'+'      Please map your data into ';str += _CfS( BRAND.NAME );str += '.'+'    </p>'+'    <a href="';str += _CfS(App.Model.Import.generateImportURL(d.importObj));str += '" class="action mini elevated">'+'      Map ';str += _CfS(App.Utils.capitalize(d.importObj.source));str += ' data'+'    </a>'+'  </div>'+'  ';} else if (d.change.new === 'done') {str += '    <div class="value">'+'      <p style="margin-top: 0;">'+'        <strong>Hooray! Your ';str += _CfS(App.Utils.capitalize(d.importObj.source));str += ' Import is complete!</strong>'+'      </p>'+'      <a href="';str += _CfS(App.Model.Import.generateImportURL(d.importObj));str += '" class="action mini elevated">'+'        View ';str += _CfS(App.Utils.capitalize(d.importObj.source));str += ' import'+'      </a>'+'    </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
