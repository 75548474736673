import * as TaskActionsTemplate from 'app/client/core/views/templates/taskActions.html?caveman';import TaskController from 'app/client/core/js/controllers/task';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'Task'], TaskController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="empty-task-container">'+'  <div class="empty-task-form" data-controller="Task">'+'    <textarea class="empty-task-description" data-on-keyup="onNewTaskKeyUp" data-on-keydown="onNewTaskKeyDown" autofocus></textarea>'+'    <div class="actions">'+'      ';str += TaskActionsTemplate.render( d);str += '    </div>'+'  </div>'+'  <a href="#" class="action micro white has-icon-left empty-task" data-controller="Task" data-on-click="openNewTaskForm" data-tabindex>'+'    ';str += (App.Controller.Task.renderAddIcon());str += '    Add Task'+'  </a>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
