import StoryModel from 'app/client/core/js/models/story';
import StoryDialogController from 'app/client/core/js/controllers/storyDialog';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Story'], StoryModel],
[['Controller', 'StoryDialog'], StoryDialogController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.nav && d.nav.prev) {str += '  <a href="#" class="prev modal-nav-item fa fa-chevron-left" data-controller="StoryDialog" data-on-click="open" data-model="Story" data-id="';str += _CfS(d.nav.prev.id);str += '" data-tabindex data-tooltip data-tooltip-fn="App.Controller.StoryDialog.getNavigationTooltip"></a>';}str += '';if (d.nav && d.nav.next) {str += '  <a href="#" class="next modal-nav-item fa fa-chevron-right" data-controller="StoryDialog" data-on-click="open" data-model="Story" data-id="';str += _CfS(d.nav.next.id);str += '" data-tabindex data-tooltip data-tooltip-fn="App.Controller.StoryDialog.getNavigationTooltip"></a>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
