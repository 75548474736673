import IterationModel from 'app/client/core/js/models/iteration';
import Format from 'app/client/core/js/modules/format';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Iteration'], IterationModel],
[['Format'], Format]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.name) {str += '<a href="';str += _CfS(d.url || App.Model.Iteration.generateURL(d));str += '" class="entity-title-link">';str += (d.formatted_name || App.Format.sanitizeAndEmojify(d.name));str += '</a>';} else {str += '<em>Deleted Iteration</em>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
