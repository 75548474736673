import * as InlineLoaderTemplate from 'app/client/core/views/templates/inlineLoader.html?caveman';import ReferralsController from 'app/client/core/js/controllers/referrals';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'Referrals'], ReferralsController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="referrals" data-controller="Referrals">'+'  <div class="referrals-content">'+'    <h1 class="referrals-title">Earn rewards for referring your friends</h1>'+''+'    <div class="referrals-container">'+'      <div id="referral-copy">'+'        ';str += InlineLoaderTemplate.render( { message: 'Fetching your unique share link...' });str += '      </div>'+''+'      <div id="referral-meta">'+'        <div id="referral-status"></div>'+'        <div id="referral-share"></div>'+'      </div>'+'    </div>'+''+'    <div id="referral-errors"></div>'+''+'    <div id="referral-cards">'+'      ';str += (App.Controller.Referrals.getFriendsCard());str += '      ';str += (App.Controller.Referrals.getYourCard());str += '    </div>'+''+'    <a class="referrals-link" href="https://';str += _CfS( BRAND.DOMAIN_CORP_WEBSITE );str += '/blog/refer-friends-get-rewards-refer-colleagues-get-rewards-refer-enemies-get-rewards">Learn more about our referral program</a>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
