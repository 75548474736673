import Utils from 'app/client/core/js/modules/utils';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Utils'], Utils]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';var pullRequest = d.pullRequest;str += '<div class="pull-tooltip">'+'  <div class="source-container">'+'    <span class="label">source: </span><span>';str += _CfS( pullRequest.branch_name );str += '</span>'+'  </div>'+'  ';if (pullRequest.target_branch_name) {str += '    <div>'+'      <span class="label">'+'        target:'+'      </span>'+'      <span class="target-branch-name">'+'        ';str += _CfS( pullRequest.target_branch_name );str += '      </span>'+'    </div>'+'  ';}str += '  <div class="pull-request-labels">'+'    <!-- PR labels -->'+'    ';_ds.push(d); var _d0 = pullRequest.vcs_labels; for (var _i0 = 0, _len0 = (pullRequest.vcs_labels || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var label = _d0[_i0];str += '    <span style="background-color: ';str += _CfS( App.Utils.fixColor(label.color) );str += '; color: ';str += _CfS( App.Utils.textForColor(label.color) );str += '" class="tagged"> ';str += _CfS( label.name );str += '</span>'+'    ';} d = _ds.pop();str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
