import ColumnModel from 'app/client/core/js/models/column';
import Is from 'app/client/core/js/modules/is';
import ColumnController from 'app/client/stories/js/controllers/column';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Column'], ColumnModel],
[['Is'], Is],
[['Controller', 'Column'], ColumnController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="column ';str += _CfS(d.class_name);str += ' ';if (App.Is.mobile() && !d.visibleOnMobile) {str += 'mobile-hidden-column';} else if (App.Is.mobile() && d.visibleOnMobile) {str += 'mobile-visible-column';}str += '" data-model="Column" data-id="';str += _CfS(d.id);str += '" data-controller="Column">'+'  ';str += (App.Controller.Column.renderColumnHeader(d.id));str += '  <div class="vertical-column-header">'+'    ';str += _CfS(d.name);str += '  </div>'+'  <div class="column-bucket">'+'    <div class="new-story-cta-container">'+'      ';str += (App.Controller.Column.renderNewStoryInput(d.id));str += '    </div>'+'    <div class="empty-state-container">'+'      ';str += (App.Controller.Column.renderEmptyColumnState(d.id));str += '    </div>'+'    <div role="list" aria-labelledby="column-count-';str += _CfS(d.id);str += ' column-title-';str += _CfS(d.id);str += '" class="bucket-content ';if (d.sortable) {str += 'sortable-column';}str += '" id="column-bucket-content-';str += _CfS(d.id);str += '"></div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
