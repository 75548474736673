import UserWarningController from 'app/client/core/js/controllers/userWarning';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'UserWarning'], UserWarningController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div data-controller="UserWarning" class="user-warning">'+'  <h2>';str += (d.title);str += '</h2>'+'  <div class="blurb">'+'    <p>';str += _CfS(d.description);str += '</p>'+'    <p>';str += (d.question);str += '</p>'+'  </div>'+'  <div class="actions">'+'    <button type="button" class="action elevated" data-on-click="close">No</button>'+'    <button type="button" class="action red" id="approve-button" data-on-click="approve">Yes</button>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
