import * as UserAvatarTemplate from 'app/client/core/views/templates/userAvatar.html?caveman';import Utils from 'app/client/core/js/modules/utils';
import MessageController from 'app/client/core/js/controllers/message';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Utils'], Utils],
[['Controller', 'Message'], MessageController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="message message-';str += _CfS(d.type);str += '" data-id="';str += _CfS(App.Utils.cssify(d.id));str += '" style="width: ';str += _CfS(d.width);str += 'px">'+'  ';if (d.user && d.user.id) {str += '    ';str += UserAvatarTemplate.render( { profile: d.user, size: 32 });str += '  ';} else if (d.icon) {str += '    <span class="message-icon fa ';str += _CfS(d.icon);str += '"></span>'+'  ';}str += '  ';if (!d.noCancel) {str += '    <span class="fa fa-times" data-controller="Message" data-on-click="close"></span>'+'  ';}str += '  <div class="text">'+'    ';str += (d.componentRender());str += '    ';str += (d.str);str += '    ';if (d.secondary) {str += '      <div class="secondary">'+'        ';str += (d.secondary);str += '      </div>'+'    ';}str += '    ';if (d.actions) {str += '      <div class="actions">';str += (d.actions);str += '</div>'+'    ';}str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
