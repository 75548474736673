import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html?caveman';
import * as GroupAutoLinkTemplate from 'app/client/core/views/templates/groupAutoLink.html?caveman';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="value">'+'  <strong>';str += (d.name);str += '</strong>'+'  ';if (d.addedGroups.length) {str += '  added ';str += GroupAutoLinkTemplate.render( d.addedGroups[0]);str += ' to'+'  ';if (d.removedGroups.length) {str += '  and'+'  ';}str += '  ';}str += '  ';if (d.removedGroups.length) {str += '  removed  ';str += GroupAutoLinkTemplate.render( d.removedGroups[0]);str += ' from'+'  ';}str += '  the Epic ';str += EpicAutoLinkTemplate.render( d.epic);str += '.'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
