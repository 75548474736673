import NotificationsController from 'app/client/core/js/controllers/notifications';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'Notifications'], NotificationsController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.showButton) {str += '  <div class="show-more" data-controller="Notifications">'+'    <button class="action mini flat-white" data-on-click="showMore">Show More</button>'+'  </div>';} else {str += '  ';if (d.hasRecentActivity) {str += '    <div class="loading-activity">Loading <span class="fa fa-star fa-spin"></span></div>'+'  ';} else {str += '    <div class="show-more" data-controller="Notifications">'+'      <button class="action mini flat-white" data-on-click="showMore">Show More</button>'+'    </div>'+'    <div class="no-activity">We haven\'t found any recent activity. Once you start creating and making changes to Stories and Epics, that activity will appear here.</div>'+'  ';}str += '';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
