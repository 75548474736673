import IterationModel from 'app/client/core/js/models/iteration';
import StoryController from 'app/client/core/js/controllers/story';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Iteration'], IterationModel],
[['Controller', 'Story'], StoryController],
[['Controller', 'AddNewStory'], AddNewStoryController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var iteration = App.Model.Iteration.getById(d.iteration_id);str += '';var iconProps = { status: !!iteration ? iteration.status : undefined };str += '<div class="left-icon">'+'  ';str += (App.Controller.AddNewStory.renderIterationIcon(iconProps));str += '</div>'+'<span data-testid="attribute-name" class="name">Iteration</span>';if (iteration) {str += '  <span class="value">';str += _CfS(iteration.name);str += '</span>';} else if (d.isMultiple) {str += '  <span class="value"><em>';str += _CfS(d.displayText);str += '</em></span>';} else {str += '  <span class="value"><em>None</em></span>';}str += '';if (d.previous_iteration_ids && d.previous_iteration_ids.length) {str += '  <div class="previous-iterations-badge" data-tooltip data-tooltip-fn="App.Controller.Story.previousIterationsTooltip">+';str += _CfS(d.previous_iteration_ids.length);str += '</div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
