import Utils from 'app/client/core/js/modules/utils';
import RecoveryCodeFormController from 'app/client/login/js/controllers/recoveryCodeForm';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Utils'], Utils],
[['Controller', 'RecoveryCodeForm'], RecoveryCodeFormController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


export function render(_d) {
  let html = '<div id="login-form" data-controller="RecoveryCodeForm" class="login-form-tab">  <form method="post" data-on-submit="login">    <div class="forgot-blurb">Lost your phone, or deleted your <span data-tooltip="Two-factor authentication">2FA</span> app? Enter your credentials and recovery code below and we\'ll disable 2FA on your account.</div>    <div class="form-input email">      <label>Username or Email</label>      <input id="login-name" type="text" name="username" spellcheck="false" class="textfield" placeholder="name@example.com" data-tabindex />    </div>    <div class="form-input password">      <label>Password</label>      <input id="login-password" type="password" name="password" spellcheck="false" class="textfield" placeholder="&bull;&bull;&bull;&bull;&bull;" data-tabindex />    </div>    <div class="form-input recovery-code">      <label>Recovery code <span class="label-optional">Only if you\'ve set up 2FA.</span></label>      <input id="login-recovery-code" type="text" name="recovery_code" spellcheck="false" class="textfield" data-tabindex />    </div>    <div class="server-messages"></div>    <div class="form-action">      <button id="login-button" class="action blue" data-on-click="login" data-tabindex>Disable 2FA <span class="fa fa-arrow-right"></span></button>    </div>    <div class="problems-logging-in">      <a href="/" data-on-click="App.Utils.fadePageOut">Or, try logging in again.</a>    </div>  </form></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
