import StoryController from 'app/client/core/js/controllers/story';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'Story'], StoryController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<a href="#" class="action micro flat-white story-diff" data-controller="Story" data-model="';str += _CfS(d.model);str += '" data-id="';str += _CfS(d.changeID);str += '" data-action-id="';str += _CfS(d.actionID);str += '" data-prop="';str += _CfS(d.prop);str += '" data-on-click="showStoryDiff">Diff</a>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
