import InPlaceEditorController from 'app/client/core/js/controllers/inPlaceEditor';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'InPlaceEditor'], InPlaceEditorController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="in-place-editor">'+'  ';if (d.multiline) {str += '    <textarea data-on-submit="';str += _CfS(d.onSave);str += '"';if (d.limit) {str += ' maxLength="';str += _CfS(d.limit);str += '"';}str += '>';str += _CfS(d.value);str += '</textarea>'+'  ';} else {str += '    <input class="textfield" data-on-submit="';str += _CfS(d.onSave);str += '" val="';str += _CfS(d.value);str += '"';if (d.limit) {str += ' maxLength="';str += _CfS(d.limit);str += '"';}str += ' />'+'  ';}str += '  <span class="saving-icon fa fa-spin fa-star"></span>'+'  <div class="actions">'+'    <a href="#" data-on-click="';str += _CfS(d.onCancel || 'App.Controller.InPlaceEditor.cancel');str += '" class="cancel">Cancel</a>'+'    <a href="#" data-on-click="';str += _CfS(d.onSave);str += '" class="save">Save Changes</a>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
