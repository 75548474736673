import EmailModel from 'app/client/core/js/models/email';
import Is from 'app/client/core/js/modules/is';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Email'], EmailModel],
[['Is'], Is]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var withinOrg = App.Is.withinOrg();str += '<div class="actionable-row" data-model="Email" data-id="';str += _CfS(d.id);str += '">'+'  ';str += _CfS(d.email_address);str += '  <div class="actionable-row-actions">'+'    ';if (!d.confirmed) {str += '      <span class="tagged orange" data-tooltip="Only confirmed emails can be made primary or be used to log in.">Unconfirmed</span>'+'      <a href="#" class="action micro white" data-on-click="sendConfirmation" data-tooltip="Resend Confirmation Email" data-tooltip-delay="0"><span class="fa fa-envelope"></span> Resend</a>'+'    ';}str += '    <a href="#" class="delete-action" data-on-click="deleteEmail" data-tooltip="Delete Email Address"><span class="fa fa-trash-o"></span></a>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
