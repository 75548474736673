import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import ManageCardController from 'app/client/settingsShared/js/controllers/manageCard';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'PaymentPlan2'], PaymentPlan2Model],
[['Controller', 'ManageCard'], ManageCardController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';const trialStatus = App.Model.PaymentPlan2.planTrialInfo(d.paymentPlan2);str += '<div class="billing-card-summary">';if (d.card) {str += '  <div class="current-card">'+'    <span class="card-icon fa ';str += _CfS(d.card.icon);str += '"></span>'+'    <div class="cardholder">';str += _CfS(d.card.name);str += '</div>'+'    <div class="last-four-digits">**** **** **** </strong>';str += _CfS(d.card.last4);str += '</strong></div>'+'    <div class="card-expiration"></strong>';str += _CfS(d.card.exp_month);str += '/';str += _CfS(d.card.exp_year);str += '</strong></div>'+'  </div>';} else {str += '  ';if (App.Model.PaymentPlan2.isTrialing(d.paymentPlan2)) {str += '    ';if (App.Model.PaymentPlan2.availableSeats(d.paymentPlan2) > 0 && !App.Model.PaymentPlan2.isAProfitablePlan(d.paymentPlan2)) {str += '      <div class="blurb">'+'        <a href="#" data-controller="ManageCard" data-on-click="open">Add a Credit Card</a>'+'      </div>'+'    ';} else {str += '      <div class="form-help-message ';if (!d.card) {str += 'warning';}str += '">'+'        <span class="help-icon fa ';if (d.card) {str += 'fa-check-circle';} else {str += 'fa-warning';}str += '"></span>'+'        <span><a href="#" data-controller="ManageCard" data-on-click="open">Add a Credit Card</a> to keep your account active on <strong>';str += _CfS( trialStatus?.trial_end );str += '</strong>.</span>'+'      </div>'+'    ';}str += '  ';}str += '';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
