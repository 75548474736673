import * as UserAvatarTemplate from 'app/client/core/views/templates/userAvatar.html?caveman';import ProfileModel from 'app/client/core/js/models/profile';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Profile'], ProfileModel]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';var isValidProfile = App.Model.Profile.isValid(d.user);str += '';var isCommentOrMention = d.classNames.indexOf('comment') !== -1 || d.classNames.indexOf('mention') !== -1 || d.classNames.indexOf('user-joined') !== -1;str += '<div class="';str += _CfS(d.classNames);str += '" data-id="';str += _CfS(d.id);str += '">'+'  <span class="notification-date" data-tooltip="';str += _CfS(d.changedDate);str += '">';str += _CfS(d.changedHour);str += '</span>'+''+'  ';if (d.profile) {str += '    ';str += UserAvatarTemplate.render( { profile: d.profile, size: 30, tooltip: true, automation: d.automation });str += '  ';} else {str += '    <span class="unknown-user fa fa-user"></span>'+'  ';}str += '  <span data-notification-content>';str += (d.html);str += '</span>'+''+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
