import Format from 'app/client/core/js/modules/format';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Format'], Format]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="story-iteration-badge-tooltip"> '+'  This Story is in <span class="tooltip-iteration-name">';str += _CfS(d.iteration);str += '.</span>'+'  ';if (d.previous_iteration_ids && d.previous_iteration_ids.length) {str += '  <div>It was previously in ';str += _CfS(App.Format.pluralize(d.previous_iteration_ids.length, 'Started Iteration', 'Started Iterations'));str += '.</div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
