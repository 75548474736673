import * as IterationListItemTemplate from 'app/client/stories/views/templates/iterationListItem.html?caveman';
import * as ToggleMoreIterationsTemplate from 'app/client/stories/views/templates/toggleMoreIterations.html?caveman';import Globals from 'app/client/core/js/_frontloader/globals';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Globals'], Globals]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="iterations unselectable">'+'  <div class="iteration-parent relative">'+'    <div class="iteration-nav-item list-nav-item ';if (d.displayStoriesNotInIterations) {str += 'active';}str += '"'+'        id="stories-not-in-iterations" data-on-click="toggleStoriesNotInIteration"'+'        data-context-menu="noIterationContextMenuItems">'+'      ';if (d.displayStoriesNotInIterations) {str += '        <span class="fa fa-check-square"></span>'+'      ';} else {str += '        <span class="fa fa-square-o"></span>'+'      ';}str += '      <span class="name">No Iteration</span>'+'    </div>'+'  </div>'+'  ';_ds.push(d); var _d0 = d.iterations; for (var _i0 = 0, _len0 = (d.iterations || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;d = _d0[_i0];str += '    ';str += IterationListItemTemplate.render( d);str += '  ';} d = _ds.pop();str += '  ';if (d.moreIterations.length > 0) {str += '    <a href="#" class="toggle-more toggle-more-iterations" data-on-click="toggleMoreIterations">';str += ToggleMoreIterationsTemplate.render( d);str += '</a>'+'    <div class="more-iterations ';if (!App.Globals.get('showMoreIterations')) {str += 'hidden';}str += '">'+'      ';_ds.push(d); var _d1 = d.moreIterations; for (var _i1 = 0, _len1 = (d.moreIterations || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;d = _d1[_i1];str += '        ';str += IterationListItemTemplate.render( d);str += '      ';} d = _ds.pop();str += '    </div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
