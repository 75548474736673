import EpicModel from 'app/client/core/js/models/epic';
import SampleWorkspaceController from 'app/client/core/js/controllers/sampleWorkspace';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Epic'], EpicModel],
[['Controller', 'SampleWorkspace'], SampleWorkspaceController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="attribute-toggle">'+'  ';if (App.Controller.SampleWorkspace.isSampleObserver()) {str += '    <div class="placeholder"><span class="fa fa-plus"></span></div>'+'  ';} else if (App.Model.Epic.currentUserIsFollower(d)) {str += '    <a href="#" data-on-click="removeMeAsFollower" data-tooltip="Remove Yourself as Follower" tabindex="0"><span class="fa fa-times"></span></a>'+'  ';} else {str += '    <a href="#" data-on-click="addMeAsFollower" data-tooltip="Follow this Epic" tabindex="0"><span class="fa fa-plus"></span></a>'+'  ';}str += '</div>'+'<div class="attribute editable-attribute epic-followers condensed" tabindex="0" data-on-click="openEpicFollowerDropdown">'+'  <span class="name">Followers</span>'+'  <span class="value">';str += (App.Model.Epic.describeFollowers(d));str += '</span>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
