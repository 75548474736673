import * as AddLabelButtonTemplate from 'app/client/core/views/templates/addLabelButton.html?caveman';
import * as LabelContainerTemplate from 'app/client/core/views/templates/labelContainer.html?caveman';import StoryModel from 'app/client/core/js/models/story';
import Is from 'app/client/core/js/modules/is';
import StoryController from 'app/client/core/js/controllers/story';
import LabelController from 'app/client/core/js/controllers/label';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'Story'], StoryModel],
[['Is'], Is],
[['Controller', 'Story'], StoryController],
[['Controller', 'Label'], LabelController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<label>Labels</label>'+'<div class="labels story-labels">'+'  ';if (d.readOnly && !d.labels.length) {str += '    <p class="none-found">No Story labels.</p>'+'  ';} else {str += '    ';if (App.Is.mobile()) {str += '      ';str += LabelContainerTemplate.render( { labels: d.labels, onRemove: 'App.Controller.Story.removeLabel' });str += '    ';} else {str += '      ';str += LabelContainerTemplate.render( { labels: d.labels, contextMenu: 'App.Controller.Label.contextMenuItemsForStory' });str += '    ';}str += '    ';if (!d.readOnly) {str += '      ';str += AddLabelButtonTemplate.render( { onClick: 'App.Controller.Story.addStoryLabel', id: 'story-dialog-add-label-dropdown' });str += '    ';}str += '  ';}str += '</div>';var impliedLabels = App.Model.Story.getImpliedLabels(d);str += '';if (impliedLabels.length > 0) {str += '  <label>Epic Labels <span class="fa fa-question-circle" data-tooltip="Labels attached to the parent Epic of this Story."></span></label>'+'  <div class="labels implied-labels">'+'    ';str += LabelContainerTemplate.render( { labels: impliedLabels, contextMenu: 'App.Controller.Label.contextMenuItemsForEpic' });str += '  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
