import Utils from 'app/client/core/js/modules/utils';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Utils'], Utils]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="referral-copy">'+'  <div class="share-link-container">'+'    <div class="attribute"><strong>Your unique share link:</strong></div>'+'    <div class="copy-form">'+'      <button class="action green clipboard" data-clipboard-target="#share-link" data-tooltip="Copy to Clipboard" data-tabindex>Copy</button>'+'      <input class="permalink" id="share-link" data-on-click="App.Utils.selectText" readonly="true" value="';str += _CfS(d.user.shareLinks.shareLink);str += '" data-tabindex />'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
