import Is from 'app/client/core/js/modules/is';
import SettingsController from 'app/client/core/js/controllers/settings';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Is'], Is],
[['Controller', 'Settings'], SettingsController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.isOrganizationSettings) {str += '<h3 class="settings-page-header">'+'  ';if (!App.Is.mobile()) {str += '  <span class="ghost">Settings <span class="fa fa-angle-right"></span></span>'+'  ';} else {str += '  <a href="#" class="ghost" data-controller="Settings" data-on-click="openMobileMenu"'+'    >Settings</span> <span class="fa fa-angle-right"></span'+'  ></a>'+'  ';}str += ' ';str += _CfS( d.pageName );str += '</h3>';} else {str += '';if (!App.Is.mobile()) {str += '<h3 class="settings-page-header">'+'  <span class="ghost">Settings <span class="fa fa-angle-right"></span></span> ';str += _CfS( d.pageName );str += '</h3>';}str += '';}return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
