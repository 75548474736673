import Url from 'app/client/core/js/modules/url';
import HelpController from 'app/client/core/js/controllers/help';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Url'], Url],
[['Controller', 'Help'], HelpController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var entity = d.entity || 'Epic';str += '<div class="page-explanation">'+'  <h3>About the Burndown Chart</h3>'+''+'  <div class="chart-explanation">'+'    <img src="';str += _CfS( d.imgSrc );str += '" alt="Example Burndown Chart" />'+''+'    <p>'+'      The Burndown Chart represents work left to do over time. The remaining work can be represented by either points or Story count, which can be configured within your workspace\'s <a href="#" data-controller="ManageEstimateScale" data-on-click="open">Estimate Scale settings</a>.'+'    </p>'+''+'    ';if (d.entity !== "Reports" && d.entity !== "Label" && d.entity !== "Iteration") {str += '      <p>'+'        The start is tied to the date that the ';str += _CfS(entity);str += ' was moved to "In Progress", and ends when the ';str += _CfS(entity);str += ' is completed. If the ';str += _CfS(entity);str += ' is still in progress, we provide a simple forward-looking projection based on the velocity of completed Stories/points from the ';str += _CfS(entity);str += ' start date until today.'+'      </p>'+'    ';}str += '  </div>'+''+'  <div class="still-have-questions">'+'    Any questions? <a href="';str += _CfS(App.Controller.Help.KNOWLEDGE_BASE_SLUGS.HELP_HOME);str += '" target="_blank">Check out our Help Center documentation <span class="fa fa-external-link"></span></a> or <a href="';str += _CfS(App.Url.getSlugPath());str += '/help" data-controller="Help" data-on-click="open">get in touch!</a>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
