import * as CompletedColumnDateHeaderTemplate from 'app/client/stories/views/templates/completedColumnDateHeader.html?caveman';import StoryLinkController from 'app/client/core/js/controllers/storyLink';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'StoryLink'], StoryLinkController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';_ds.push(d); var _d0 = d.stories; for (var _i0 = 0, _len0 = (d.stories || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var story = _d0[_i0];str += '    ';if (_i < d.limit) {str += '        ';if (d.shouldRenderMovedAtDate(story)) {str += '            ';str += CompletedColumnDateHeaderTemplate.render( { dateString: d.format(story.moved_at || story.completed_at) });str += '        ';}str += '        ';str += (App.Controller.StoryLink.renderStoryCard(story));str += '    ';}str += '';} d = _ds.pop();return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
