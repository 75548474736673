import * as BulkDeleteDialogActionsTemplate from 'app/client/core/views/templates/bulkDeleteDialogActions.html?caveman';
import * as StoryLinkTemplate from 'app/client/core/views/templates/storyLink.html?caveman';import BulkSelectionModel from 'app/client/core/js/models/bulkSelection';
import BulkEditorController from 'app/client/core/js/controllers/bulkEditor';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Model', 'BulkSelection'], BulkSelectionModel],
[['Controller', 'BulkEditor'], BulkEditorController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="bulk-delete-dialog" data-controller="BulkEditor" data-model="BulkSelection">'+'  <h3>Delete multiple stories</h3>'+''+'  <div class="form-help-message"><span class="help-icon fa fa-info-circle"></span> Deleting stories is an irreversible change. Please consult the list below before continuing, to avoid any accidental deletions.</div>'+''+'  <div class="delete-stories-list bind-selection-toggle">'+'    ';_ds.push(d); var _d0 = d.stories; for (var _i0 = 0, _len0 = (d.stories || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;d = _d0[_i0];str += '      ';str += StoryLinkTemplate.render( d);str += '    ';} d = _ds.pop();str += '  </div>'+''+'  <div class="actions">'+'    ';str += BulkDeleteDialogActionsTemplate.render( d);str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
