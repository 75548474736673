import * as PasswordStrengthTemplate from 'app/client/core/views/templates/passwordStrength.html?caveman';import ResetYourPasswordController from 'app/client/login/js/controllers/resetYourPassword';

  window.AppAssignments = window.AppAssignments || [];
  window.AppAssignments.push(() => {
    window.App = window.App || { Controller: {}, Model: {} };
    
    [
      [['Controller', 'ResetYourPassword'], ResetYourPasswordController]
    ].reduce((accum, [op, n]) => {
      op.reduce((obj, part) => {
        return obj[part] || (obj[part] = n);
      }, accum);
      return accum;
    }, window.App);
  });


import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="reset-form" data-controller="ResetYourPassword" class="login-form-tab" data-email="';str += _CfS(d.email);str += '">'+'  <form method="post" action="/reset" data-on-submit="savePassword">'+''+'    <div class="forgot-blurb">Welcome back, <strong>';str += _CfS(d.email);str += '</strong>!<br />Please enter your new password.</div>'+''+'    <div class="form-input password password-strength-container" data-validate="notEmpty">'+'      <label>Password</label>'+'      <input id="password-field" type="password" name="password" spellcheck="false" class="textfield" data-tabindex />'+'      ';str += PasswordStrengthTemplate.render( d);str += '    </div>'+''+'    <div class="form-input confirm-password" data-validate="match" data-matching="#reset-form .password input">'+'      <label>Confirm Password</label>'+'      <input id="signup-password" type="password" name="password_confirm" spellcheck="false" class="textfield" data-tabindex />'+'    </div>'+''+'    <div class="server-messages"></div>'+''+'    <div class="form-action">'+'      <button id="reset-button" class="action blue" data-on-click="savePassword" data-tabindex>Save Password <span class="fa fa-arrow-right"></span></button>'+'    </div>'+''+'  </form>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
